var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-empresa","title":_vm.tituloModal,"no-close-on-backdrop":"","ok-disabled":_vm.submitted,"cancel-disabled":_vm.submitted,"no-close-on-esc":"","ok-title":"Guardar","ok-variant":"primary","cancel-variant":"outline-secondary","cancel-title":"Cerrar","button-size":"sm"},on:{"cancel":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"close":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalEmpresa),callback:function ($$v) {_vm.modalEmpresa=$$v},expression:"modalEmpresa"}},[_c('b-overlay',{attrs:{"show":_vm.submitted,"no-wrap":"","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"lg"}}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label":"* NIT","label-for":"nit"}},[_c('validation-provider',{attrs:{"name":"NIT","rules":"required|min:10|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nit","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validar()}},model:{value:(_vm.form.nit),callback:function ($$v) {_vm.$set(_vm.form, "nit", $$v)},expression:"form.nit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nit),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label":"* RAZON SOCIAL","label-for":"razon_social"}},[_c('validation-provider',{attrs:{"name":"Razon social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"razon_social","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validar()}},model:{value:(_vm.form.razon_social),callback:function ($$v) {_vm.$set(_vm.form, "razon_social", $$v)},expression:"form.razon_social"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.razon_social),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label":"* TIPO CONTRIBUYENTE","label-for":"tipo_contribuyente"}},[_c('validation-provider',{attrs:{"name":"Tipo Contribuyente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.tipoContribuyenteItems,"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.tipo_contribuyente),callback:function ($$v) {_vm.$set(_vm.form, "tipo_contribuyente", $$v)},expression:"form.tipo_contribuyente"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.tipo_contribuyente),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label":"* TIPO MODALIDAD","label-for":"tipo-modalidad"}},[_c('validation-provider',{attrs:{"name":"Tipo Modalidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.tipoModalidadItems,"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.tipo_modalidad),callback:function ($$v) {_vm.$set(_vm.form, "tipo_modalidad", $$v)},expression:"form.tipo_modalidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.tipo_modalidad),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }