import axiosIns from "@/libs/axios";

const get = async ({ page, limit, searchQuery }) => {
  return await axiosIns.get(`empresas`)
}

const store = async (data) => {
  return await axiosIns.post('empresas', data)
}

const update = async (id, data) => {
  return await axiosIns.put(`empresas/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`empresas/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy
}
