<template>
  <b-modal
    id="modal-from-empresa"
    v-model="modalEmpresa"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="lg"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="* NIT"
              label-for="nit"
            >
              <validation-provider
                #default="{ errors }"
                name="NIT"
                rules="required|min:10|positive"
              >
                <b-form-input
                  id="nit"
                  v-model="form.nit"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nit"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="* RAZON SOCIAL"
              label-for="razon_social"
            >
              <validation-provider
                #default="{ errors }"
                name="Razon social"
                rules="required"
              >
                <b-form-input
                  id="razon_social"
                  v-model="form.razon_social"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.razon_social"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="* TIPO CONTRIBUYENTE"
              label-for="tipo_contribuyente"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo Contribuyente"
                rules="required"
              >
                <b-form-select
                  v-model="form.tipo_contribuyente"
                  :options="tipoContribuyenteItems"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.tipo_contribuyente"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="4"
              label="* TIPO MODALIDAD"
              label-for="tipo-modalidad"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo Modalidad"
                rules="required"
              >
                <b-form-select
                  v-model="form.tipo_modalidad"
                  :options="tipoModalidadItems"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.tipo_modalidad"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import EmpresaServices from "../services/index";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        nit: "",
        razon_social: "",
        tipo_contribuyente: null,
        tipo_modalidad: null,
      },
      required,
      submitted: false,
      errores: [],
      modalEmpresa: false,
      tipoContribuyenteItems: [
        { text: "PERSONA JURÍDICA", value: "PERSONA JURÍDICA" },
        { text: "PERSONA NATURAL", value: "PERSONA NATURAL" },
        { text: "SUCESIÓN INDIVISA", value: "SUCESIÓN INDIVISA" },
        { text: "EMPRESA UNIPERSONAL", value: "EMPRESA UNIPERSONAL" },
      ],
      tipoModalidadItems: [
        { text: "COMPUTARIZADA EN LINEA", value: "COMPUTARIZADA EN LINEA" },
        { text: "ELECTRÓNICA EN LINEA", value: "ELECTRÓNICA EN LINEA" },
      ],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR EMPRESA" : "NUEVA EMPRESA";
    },
  },
  watch: {
    "form.nit": function () {
      this.errores.nit = [];
    },
    "form.razon_social": function () {
      this.errores.razon_social = [];
    },
    "form.tipo_modalidad": function () {
      this.errores.tipo_modalidad = [];
    },
    "form.tipo_contribuyente": function () {
      this.errores.tipo_contribuyente = [];
    },
  },
  methods: {
    // VALIDAR EL FORMULARIO
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    // ABRIR EL MODAL
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.nit = "";
          this.form.razon_social = "";
          this.form.tipo_contribuyente = null;
          this.form.tipo_modalidad = null;
          this.modalEmpresa = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.nit = data["nit"];
          this.form.razon_social = data["razon_social"];
          this.form.tipo_contribuyente = data["tipo_contribuyente"];
          this.form.tipo_modalidad = data["tipo_modalidad"];
          this.modalEmpresa = true;
          break;
        }
      }
      this.errores = [];
    },
    // CERRAR EL MODAL
    cerrarModal() {
      this.form.id = null;
      this.modalEmpresa = false;
    },
    actualizarEmpresa(item) {
      this.form.id = null;
      this.modalEmpresa = false;
      this.$emit("actualizarEmpresa", item);
      this.submitted = false;
    },

    // SERVICES
    async registrar() {
      try {
        const response = await EmpresaServices.store(this.form);
        this.actualizarEmpresa(response.data.empresa);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await EmpresaServices.update(this.form.id, this.form);
        this.actualizarEmpresa(response.data.empresa);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    // FORMATEAR INPUTS
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
};
</script>